<template>
  <div>
    <b-modal
      id="modal-update-game-type"
      cancel-variant="outline-secondary"
      :ok-title="$t('Confirm')"
      :cancel-title="$t('Close')"
      ok-variant="success"
      centered
      modal-class="modal-success"
      :title="$t('Update game type')"
      @ok="confirm"
      @hidden="resetModal()"
    >
      <!-- form -->
      <validation-observer ref="updateGameTypeModal">
        <b-form method="POST" @submit.prevent="validationForm">
          <b-form-group label-for="name">
            <label class="mb-1">{{ $t("Name") }}</label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="gameType.name"
                disabled
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="player_number">
            <label class="mb-1">{{ $t("Player Number") }}</label>
            <validation-provider
              #default="{ errors }"
              name="player_number"
              rules="required"
            >
              <b-form-input
                id="player_number"
                v-model="gameType.player_number"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="order">
            <label class="mb-1">{{ $t("order") }}</label>
            <validation-provider
              #default="{ errors }"
              name="order"
              rules="required"
            >
              <b-form-input
                id="order"
                v-model="gameType.order"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="img">
            <label for="">
              {{ $t("img") }}
              <span class="text-secondary">(Không > 10Mb)</span></label
            >
            <validation-provider #default="{ errors }" name="img">
              <!-- rules="size:10240" -->
              <b-form-file
                v-model="gameType.img"
                input-id="img"
                accept="image/jpeg,image/png,image/gif,image/svg,image/jpg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <b-img
              :src="`${gameType.img ? gameType.showImg : ''}`"
              fluid
              style="width: 150px"
              class="mr-2 rounded"
            />
          </b-form-group>
          <b-button
            v-show="gameType.img"
            variant="outline-danger"
            class="btn-sm btn-icon"
            @click="gameType.img = null"
          >
            <feather-icon icon="XIcon" />
          </b-button>

          <b-form-group label-for="status">
            <label class="mb-1 d-block">Status</label>
            <b-form-radio
              v-model="gameType.status"
              name="status"
              value="1"
              inline
            >
              Active
            </b-form-radio>
            <b-form-radio
              v-model="gameType.status"
              name="status"
              value="2"
              inline
            >
              Inactive
            </b-form-radio>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
  BLink,
  BRow,
  BCol,
  BTabs,
  BTab,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormRadio,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
  BImg,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import baseUrl from "@/libs/base-url";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BTabs,
    BTab,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormRadio,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BBadge,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BImg,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      gameType: {
        // id: "",
        game_type_id: "",
        name: "",
        title: "",
        code: "",
        player_number: "",
        order: "",
        type: "",
        img: "",
        showImg: null,
        status: "",
      },
      gameTypeNoImg: {
        game_type_id: "",
        name: "",
        title: "",
        code: "",
        player_number: "",
        order: "",
        type: "",
        img: "",
        showImg: null,
        status: "",
      },
    };
  },
  props: {
    gameTypeDetail: {
      type: Object,
      default: null,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
  },
  watch: {
    gameTypeDetail: {
      handler(newVal) {
        if (newVal) {
          this.gameType = {
            // id: newVal.id,
            game_type_id: newVal.id,
            name: newVal.name,
            title: newVal.title,
            code: newVal.code,
            player_number: newVal.player_number,
            order: newVal.order,
            type: newVal.type,
            img: newVal.img,
            showImg: baseUrl + newVal.img,
            status: newVal.status,
          };
        }
      },
      deep: true,
    },
  },
  methods: {
    validationForm() {
      this.$refs.updateGameTypeModal.validate().then((success) => {
        if (success) {
          if (typeof this.gameType.img !== "string") {
            this.updateGameTypeData(this.gameType);
          } else {
            this.updateGameTypeData({
              game_type_id: this.gameType.game_type_id,
              name: this.gameType.name,
              title: this.gameType.title,
              code: this.gameType.code,
              player_number: this.gameType.player_number,
              order: this.gameType.order,
              type: this.gameType.type,
              img: "",
              showImg: baseUrl + this.gameType.img,
              status: this.gameType.status,
            });
          }
        }
      });
    },
    async updateGameTypeData(gameTypeData) {
      await this.$store
        .dispatch("agent/updateGameType", gameTypeData)
        .then((response) => {
          console.log(123123, gameTypeData);
          if (response.status === 200) {
            if (response.data.code == "00") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || "success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$emit("refetch-data");
              this.$nextTick(() => {
                setTimeout(() => {
                  this.$bvModal.hide("modal-update-game-type");
                  this.resetModal();
                }, "500");
              });
              this.$emit("refetch-data");
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          }
        });
    },
    resetModal() {
      this.gameType = {
        game_type_id: "",
        name: "",
        title: "",
        code: "",
        player_number: "",
        order: "",
        type: "",
        img: null,
      };
      this.$emit("update:gameTypeDetail", null);
    },
    confirm(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationForm();
    },
  },
  setup() {
    return {
      baseUrl,
    };
  },
};
</script>
