var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-update-game-type","cancel-variant":"outline-secondary","ok-title":_vm.$t('Confirm'),"cancel-title":_vm.$t('Close'),"ok-variant":"success","centered":"","modal-class":"modal-success","title":_vm.$t('Update game type')},on:{"ok":_vm.confirm,"hidden":function($event){return _vm.resetModal()}}},[_c('validation-observer',{ref:"updateGameTypeModal"},[_c('b-form',{attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-for":"name"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Name")))]),_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","disabled":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.gameType.name),callback:function ($$v) {_vm.$set(_vm.gameType, "name", $$v)},expression:"gameType.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"player_number"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Player Number")))]),_c('validation-provider',{attrs:{"name":"player_number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"player_number","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.gameType.player_number),callback:function ($$v) {_vm.$set(_vm.gameType, "player_number", $$v)},expression:"gameType.player_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"order"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("order")))]),_c('validation-provider',{attrs:{"name":"order","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"order","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.gameType.order),callback:function ($$v) {_vm.$set(_vm.gameType, "order", $$v)},expression:"gameType.order"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"img"}},[_c('label',{attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t("img"))+" "),_c('span',{staticClass:"text-secondary"},[_vm._v("(Không > 10Mb)")])]),_c('validation-provider',{attrs:{"name":"img"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"input-id":"img","accept":"image/jpeg,image/png,image/gif,image/svg,image/jpg"},model:{value:(_vm.gameType.img),callback:function ($$v) {_vm.$set(_vm.gameType, "img", $$v)},expression:"gameType.img"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('b-img',{staticClass:"mr-2 rounded",staticStyle:{"width":"150px"},attrs:{"src":("" + (_vm.gameType.img ? _vm.gameType.showImg : '')),"fluid":""}})],1),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.gameType.img),expression:"gameType.img"}],staticClass:"btn-sm btn-icon",attrs:{"variant":"outline-danger"},on:{"click":function($event){_vm.gameType.img = null}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1),_c('b-form-group',{attrs:{"label-for":"status"}},[_c('label',{staticClass:"mb-1 d-block"},[_vm._v("Status")]),_c('b-form-radio',{attrs:{"name":"status","value":"1","inline":""},model:{value:(_vm.gameType.status),callback:function ($$v) {_vm.$set(_vm.gameType, "status", $$v)},expression:"gameType.status"}},[_vm._v(" Active ")]),_c('b-form-radio',{attrs:{"name":"status","value":"2","inline":""},model:{value:(_vm.gameType.status),callback:function ($$v) {_vm.$set(_vm.gameType, "status", $$v)},expression:"gameType.status"}},[_vm._v(" Inactive ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }